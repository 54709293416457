import { Link, Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Moment from 'moment';

// material
import {
  Card,
  Table,
  Stack,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Box,
  IconButton,
  styled,
  OutlinedInput,
  InputAdornment,
  Avatar,
  Popover,
  Tooltip,
  Chip,
} from '@mui/material';
import { Close, Comment, ContentCopy, RemoveRedEye, ThumbDownAlt, ThumbUpAlt, Visibility } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// components
import { useEffect, useState } from 'react';
import Iconify from '../components/Iconify';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Popup from '../components/popups/FeedStatusChange';
import FeedPopular from '../components/popups/FeedPopular';
import Filter from '../components/filters/Feeds';

// ----------------------------------------------------------------------

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

export default function Feed() {
  const accountData = JSON.parse(localStorage.getItem('profile'));
  const isSuperAdmin = accountData.accType === 'SUPER_ADMIN';
  const adminId = accountData._id;
  const navigate = useNavigate();
  const [feedData, setFeedData] = useState();
  const [pages, setPages] = useState(0);
  const [feedCount, setFeedCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [input, setInput] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedIndex1, setSelectedIndex1] = useState();
  const [selectedIndex2, setSelectedIndex2] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open3, setOpen3] = useState(false);
  const [search, setSearch] = useState('');

  const getFeedPosts = async (options) => {
    setIsLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/feed/admin/all`, {
        params: options,
      });
      console.log('feed posts', res);
      setFeedData(res.data.data.records);
      setFeedCount(res.data.data.maxRecords);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getFeedPostsWithoutLoading = async (options) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/feed/admin/all`, {
        params: options,
      });
      console.log('feed posts', res);
      setFeedData(res.data.data.records);
      setFeedCount(res.data.data.maxRecords);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.isBlocked}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };

  const handlePopularChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.isPopular}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex2(index);
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    setSelectedIndex2();
    if (refresh) {
      getFeedPosts(options);
    }
    if (message) {
      toast(message);
    }
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getFeedPosts(temp);
  };

  const onSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
    const temp = { size, page: 0, search: e.target.value };
    setOptions(temp);
    setPages(0);
    getFeedPostsWithoutLoading(temp);
  };

  const handleOpenPopover = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex1(index);
    setOpen3(true);
    event.stopPropagation();
  };

  const handleClosePopover = (event) => {
    setAnchorEl(null);
    setSelectedIndex1('');
    setOpen3(false);
    event.stopPropagation();
  };

  const goToPage = () => {
    if (input > Math.ceil(feedCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getFeedPosts(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.id.length) {
      temp.id = filter.id;
    }
    if (filter.title.length) {
      temp.title = filter.title;
    }
    if (filter.countryId.length) {
      temp.countryId = filter.countryId;
    }
    if (filter.startDate) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate) {
      temp.endDate = filter.endDate;
    }
    if (filter.isBlocked.length) {
      temp.isBlocked = filter.isBlocked;
    }
    if (filter.isPopular.length) {
      temp.isPopular = filter.isPopular;
    }
    if (filter.authorId.length) {
      temp.authorId = filter.authorId;
    }
    setOptions(temp);
    setPages(0);
    getFeedPosts(temp);
  };

  const handleTableCellClick = (item) => {
    if (item.isBlocked) {
      return;
    }
    const url = `${process.env.REACT_APP_USER_URL}/${item.url || item._id}`;
    window.open(url, '_blank');
  };

  const getTableCellStyles = (item) => ({
    padding: 0,
    cursor: item.isBlocked ? 'default' : 'pointer',
    pointerEvents: item.isBlocked ? 'none' : 'auto',
  });

  useEffect(() => {
    getFeedPosts(options);
  }, []);

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setOptions(temp);
    setPages(0);
    getFeedPosts(temp);
  }, [size]);

  return (
    <Page title="Feeds">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Container maxWidth="xl">
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h4">Feeds</Typography>
            </Stack>
            <Card>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={2} padding={2}>
                  <TextField
                    size="small"
                    style={{ maxWidth: '100px', maxHeight: '35px' }}
                    type="number"
                    component="div"
                    label="Go to"
                    value={input}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        goToPage();
                      }
                    }}
                    onInput={(e) => setInput(e.target.value)}
                  />
                  <Button variant="contained" onClick={goToPage} style={{ maxHeight: '35px' }}>
                    Go
                  </Button>
                </Stack>
                <Stack direction="row" spacing={2} padding={2}>
                  <SearchStyle
                    value={search}
                    onChange={onSearch}
                    placeholder="Search Feed..."
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                      </InputAdornment>
                    }
                  />
                </Stack>
                <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                  <Button
                    startIcon={<Iconify icon="eva:plus-fill" />}
                    onClick={() => navigate('/dashboard/feed/add')}
                    variant="contained"
                  >
                    Add Feed
                  </Button>
                  <Filter applyFilters={applyFilters} />
                </Stack>
              </Stack>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    {/* <FeedListHead /> */}
                    <TableHead>
                      <TableRow>
                        <TableCell>Sl No</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell sx={{ padding: 0 }}>Author</TableCell>
                        <TableCell sx={{ padding: 0 }}>Image</TableCell>
                        <TableCell sx={{ padding: 0 }}>Title</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Feed ID</TableCell>
                        <TableCell>Responses</TableCell>
                        <TableCell>Pinned</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {feedData?.map((item, id) => (
                        <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#FFE2E2' } }}>
                          <TableCell>{pages * size + (id + 1)}</TableCell>
                          <TableCell sx={{ minWidth: '120px' }}>
                            {Moment(item.createdAt).format('YYYY-MM-DD')}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              paddingLeft: 0,
                            }}
                          >
                            <Tooltip title={item.authorName}>
                              <Avatar
                                alt="User"
                                src={item.authorProfilePic && item.authorProfilePic.fileUrl}
                                onClick={(e) => handleOpenPopover(e, id)}
                                sx={{ width: 48, height: 48, cursor: 'pointer' }}
                              />
                            </Tooltip>
                            <Popover
                              open={open3 && selectedIndex1 === id}
                              anchorEl={anchorEl}
                              onClose={handleClosePopover}
                              disableRestoreFocus
                              anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'center',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                            >
                              <Box
                                sx={{
                                  p: 2,
                                  maxWidth: 280,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  flexDirection: 'column',
                                }}
                              >
                                <IconButton
                                  onClick={handleClosePopover}
                                  sx={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    color: 'text.secondary',
                                  }}
                                >
                                  <Close />
                                </IconButton>
                                <Avatar
                                  src={item.authorProfilePic && item.authorProfilePic.fileUrl}
                                  alt="Avatar"
                                  sx={{ width: 200, height: 200 }}
                                />
                                <Typography
                                  sx={{
                                    lineHeight: 1.2,
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {item?.authorName}
                                </Typography>
                                <Typography variant="body2" align="center" sx={{ color: 'text.secondary' }}>
                                  {item.authorEmail}
                                </Typography>
                              </Box>
                            </Popover>
                          </TableCell>
                          <TableCell
                            sx={{
                              minWidth: '110px',
                              padding: 0,
                            }}
                          >
                            <Box component="img" src={item?.image?.fileURL} sx={{ width: '100px', height: '60px' }} />
                          </TableCell>
                          <TableCell sx={getTableCellStyles(item)}>{item.title}</TableCell>
                          <TableCell>
                            {Array.isArray(item?.countryName) ? (
                              item?.countryName.map((country) => (
                                <Chip key={country} label={country} sx={{ mr: 1, mb: 1 }} />
                              ))
                            ) : (
                              <Chip label={item?.countryName} sx={{ mr: 1, mb: 1 }} />
                            )}
                          </TableCell>

                          <TableCell style={{ width: '100px', cursor: 'pointer' }}>
                            <Stack direction="column" spacing={0.5} width="80px" alignItems={'start'}>
                              <CopyToClipboard text={item._id} onCopy={() => toast.info('Feed id copied')}>
                                <Stack direction="row" alignItems="center" spacing={0.5} justifyContent="flex-start">
                                  <IconButton sx={{ margin: 0, padding: 0 }} color="primary">
                                    <ContentCopy sx={{ fontSize: '20px' }} />
                                  </IconButton>
                                  <Typography variant="p" color="primary">
                                    Copy ID
                                  </Typography>
                                </Stack>
                              </CopyToClipboard>
                              <Stack
                                direction={'row'}
                                justifyContent={'center'}
                                alignItems="center"
                                onClick={() => handleTableCellClick(item)}
                              >
                                <IconButton sx={{ margin: 0, padding: 0 }} color="primary">
                                  <RemoveRedEye sx={{ fontSize: '20px' }} />
                                </IconButton>
                                <Typography variant="p" color="primary" sx={{ wordBreak: 'keep-all', width: '100px' }}>
                                  View Feed
                                </Typography>
                              </Stack>
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <Stack direction="column" spacing={0.5} alignItems="start">
                              <Stack direction="row" justifyContent="start" alignItems="center" spacing={1}>
                                <RemoveRedEye
                                  sx={{
                                    fontSize: '18px',
                                    color: 'primary',
                                  }}
                                />
                                <Typography color="primary">{item.viewCount}</Typography>
                              </Stack>
                              <Stack direction="row" justifyContent="start" alignItems="center" spacing={1}>
                                <Comment
                                  sx={{
                                    fontSize: '18px',
                                    color: 'greenish.dark',
                                  }}
                                />
                                <Typography color="greenish.dark"> {item.comments}</Typography>
                              </Stack>
                              <Stack direction="row" justifyContent="start" alignItems="center" spacing={1}>
                                <ThumbUpAlt
                                  sx={{
                                    fontSize: '18px',
                                    color: 'purplee.dark',
                                  }}
                                />
                                <Typography color="purplee.dark"> {item.likeCount}</Typography>
                              </Stack>
                              <Stack direction="row" justifyContent="start" alignItems="center" spacing={1}>
                                <ThumbDownAlt
                                  sx={{
                                    fontSize: '18px',
                                    color: 'purplee.dark',
                                  }}
                                />
                                <Typography color="purplee.dark"> {item.dislikeCount}</Typography>
                              </Stack>
                            </Stack>
                          </TableCell>
                          <TableCell>
                            {isSuperAdmin ? (
                              <FormControl sx={{ minWidth: 100 }}>
                                <Select
                                  size="small"
                                  value={item.isPopular}
                                  onChange={(e) => handlePopularChange(e, item, id)}
                                >
                                  <MenuItem value="true">Yes</MenuItem>
                                  <MenuItem value="false">No</MenuItem>
                                </Select>
                                {selectedIndex2 === id && <FeedPopular item={item} handleClose={handleClose} />}
                              </FormControl>
                            ) : (
                              <Typography>{item.isPopular ? 'Yes' : 'No'}</Typography>
                            )}
                          </TableCell>
                          <TableCell>
                            <Stack direction="column" justifyContent="center" alignItems="center" spacing={0.5}>
                              {isSuperAdmin || adminId === item.adminId ? (
                                <FormControl sx={{ minWidth: 100 }}>
                                  <Select
                                    size="small"
                                    value={item.isBlocked}
                                    onChange={(e) => handleStatusChange(e, item, id)}
                                  >
                                    <MenuItem value="false">Active</MenuItem>
                                    <MenuItem value="true">Blocked</MenuItem>
                                  </Select>
                                  {selectedIndex === id && <Popup item={item} handleClose={handleClose} />}
                                </FormControl>
                              ) : (
                                <Typography>{item.isBlocked ? 'Blocked' : 'Active'}</Typography>
                              )}
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <IconButton
                              sx={{ margin: 0, padding: 0 }}
                              color="primary"
                              onClick={() => window.open(`/dashboard/feed/view/${item._id}`, '_blank')}
                            >
                              <Visibility sx={{ fontSize: '20px' }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                onRowsPerPageChange={(e) => {
                  setSize(e.target.value);
                }}
                component={'div'}
                showFirstButton
                showLastButton
                count={feedCount}
                onPageChange={handlePageChange}
                rowsPerPage={size}
                page={pages}
              />
            </Card>
          </Container>
        </>
      )}
    </Page>
  );
}
