import { Box, Stack, Typography } from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import { Link, useNavigate } from "react-router-dom";
// import BannerText from "./BannerText";
import "./probannerSlider.css";

export default function Banner({image}) {
  const navigate = useNavigate();
  const showNav = true;
  const images = image.map((item)=>({
      original: item.fileURL,
    }))


  return (
    <Box
      className="probanner"
      sx={{
        maxWidth: "600px",
        margin: "0 auto",
      }}
    >
      <ImageGallery
        items={images}
        // autoPlay={true}
        showThumbnails={false}
        // showBullets={true}
        showFullscreenButton={false}
        showPlayButton={false}
        showNav={showNav}
        infinite={showNav}
        slideDuration={800}
        slideRobotoval={5000}
        additionalClass="promobanner"
        renderItem={((item)=>(
            <img
            src={item.original}
            alt="offer"
            data-id={item._id}
            style={{cursor: "pointer"}}
          />
          ))}
      />
    </Box>
  );
}
