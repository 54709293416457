import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box, Typography } from '@mui/material';
// components
import { BaseOptionChart } from '../../../components/chart';

// ----------------------------------------------------------------------

BarAndLineGraphNoCard.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function BarAndLineGraphNoCard({ title, subheader, chartLabels, chartData, ...other }) {
  const updatedChartData = chartData.map((item) => ({
    ...item,
    type: item.chartType,
  }));
  const chartOptions = merge(BaseOptionChart(), {
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: {
      type: 'text',
      min: 1,
      max: chartLabels.length < 5 ? 6 : chartLabels.length + 1,
      axisBorder: {
        show: true,
        color: '#78909C',
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
        color: '#78909C',
      },
    },
    grid: {
      strokeDashArray: 1,
      borderColor: '#90A4AE',
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} `;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card
      {...other}
      sx={{
        boxShadow: 'none',
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Archivo, sans-serif',
          fontWeight: 'bold',
          fontSize: '20px',
        }}
        textAlign="center"
      >
        {title}
      </Typography>
      <Typography
        sx={{ fontSize: '14px', fontFamily: 'Archivo, sans-serif' }}
        variant="body1"
        color="grey.700"
        textAlign="center"
        marginBottom={3}
      >
        {subheader?.charAt(0)?.toUpperCase() + subheader?.slice(1)}
      </Typography>

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={updatedChartData} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
