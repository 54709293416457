import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Button,
  Card,
  Container,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Autocomplete,
  CircularProgress,
  Avatar,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

/// file pond //

import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'; // Crops image
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'; // Changes image to match crop

import Page from '../components/Page';
import Iconify from '../components/Iconify';

registerPlugin(FilePondPluginImagePreview, FilePondPluginImageCrop, FilePondPluginImageTransform);

/// editor //

// Register the plugins

export default function ViewAdvertisement() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const truevalue = true;
  const [imageError, setImageError] = useState(false);
  const [advertisers, setAdvertisers] = useState([]);
  const [open5, setOpen5] = useState(false);
  const [loading5, setLoading5] = useState(true);
  const [ad, setAd] = useState({});
  const [editMode, setEditMode] = useState(false);

  const adSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    advertiserId: Yup.string().required('Advertiser is required'),
    device: Yup.string().required('Device is required'),
    isBlocked: Yup.string().required('Blocked is required'),
  });

  const formik = useFormik({
    initialValues: {
      advertiserId: '',
      name: '',
      device: 'DESKTOP',
      isBlocked: 'true',
      link: '',
    },
    // validationSchema: adSchema,
    onSubmit: async () => {
      console.log(values);
      const data = new FormData();
      data.append('name', values.name);
      data.append('advertiserId', values.advertiserId);
      data.append('device', values.device);
      data.append('isBlocked', values.isBlocked);
      data.append('id', id);
      data.append('link', values.link);
      files.forEach((item) => {
        data.append('ad', item.file);
      });
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/ad/advertisement`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        toast(res.data.message);
        setEditMode(false);
        getAd();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const getAdvertisers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/ad/advertisers-active`);
      console.log(res.data);
      setAdvertisers(res.data.data.advertisers);
      setLoading5(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const setFormValues = (ad) => {
    formik.setFieldValue('name', ad.name);
    formik.setFieldValue('advertiserId', ad.advertiserId._id);
    formik.setFieldValue('device', ad.device);
    formik.setFieldValue('isBlocked', ad.isBlocked);
    formik.setFieldValue('link', ad.link);
  };

  const getAd = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/ad/advertisement/`, { params: { id } });
      console.log(res.data);
      setAd(res.data.data.advertisement);
      setFormValues(res.data.data.advertisement);
      setLoading5(false);
    } catch (error) {
      console.log(error);
    }
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  useEffect(() => {
    const image = [];
    files.forEach((item) => {
      image.push(item.file);
    });
  }, [files]);

  useEffect(() => {
    getAdvertisers();
    getAd();
  }, []);

  return (
    <Page name="View Ad">
      <Container maxWidth="xl">
        <Card>
          <Stack direction={'row'} justifyContent="space-between" padding={1}>
            <Typography variant="h4"> Advertisement</Typography>
            <Stack direction={'row'} spacing={2}>
              {!editMode && (
                <Button variant="contained" size="small" onClick={() => setEditMode(true)}>
                  Edit
                </Button>
              )}
              <Iconify style={{ cursor: 'pointer', fontSize: '20px' }} onClick={() => navigate(-1)} icon="fe:close" />
            </Stack>
          </Stack>
          <Box sx={{ paddingX: 1, paddingY: 2 }}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      label="Name"
                      fullWidth
                      disabled={!editMode}
                      sx={{ width: '100%' }}
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>
                  {editMode ? (
                    <Grid item xs={6} lg={3} md={4}>
                      <Autocomplete
                        id="asynchronous-demo"
                        fullWidth
                        open={open5}
                        onOpen={() => {
                          setOpen5(true);
                        }}
                        onClose={() => {
                          setOpen5(false);
                        }}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        getOptionLabel={(option) => option.name}
                        options={advertisers}
                        value={advertisers.find((advertiser) => advertiser._id === formik.values.advertiserId) || null}
                        onChange={(_, value) => {
                          if (value) {
                            formik.setFieldValue('advertiserId', value._id);
                          } else {
                            formik.setFieldValue('advertiserId', '');
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Advertiser"
                            InputProps={{
                              ...params.InputProps,
                            }}
                            error={Boolean(touched.advertiserId && errors.advertiserId)}
                            helperText={touched.advertiserId && errors.advertiserId}
                          />
                        )}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={6} lg={3} md={4} sm={4}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        label="Advertiser"
                        fullWidth
                        disabled
                        sx={{ width: '100%' }}
                        value={ad?.advertiserId?.name}
                      />
                    </Grid>
                  )}

                  <Grid item xs={6} md={4} lg={2}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      select
                      label="Device"
                      fullWidth
                      disabled={!editMode}
                      sx={{ width: '100%' }}
                      {...getFieldProps('device')}
                      error={Boolean(touched.device && errors.device)}
                      helperText={touched.device && errors.device}
                    >
                      <MenuItem value={'MOBILE'}>Mobile</MenuItem>
                      <MenuItem value={'DESKTOP'}>Desktop</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={6} md={4} lg={2}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      select
                      label="Status"
                      fullWidth
                      disabled={!editMode}
                      sx={{ width: '100%' }}
                      {...getFieldProps('isBlocked')}
                      error={Boolean(touched.isBlocked && errors.isBlocked)}
                      helperText={touched.isBlocked && errors.isBlocked}
                    >
                      <MenuItem value={'false'}>Active</MenuItem>
                      <MenuItem value={'true'}>Blocked</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={12} lg={5}>
                    <TextField
                      type="text"
                      label="Link"
                      fullWidth
                      disabled={!editMode}
                      sx={{ width: '100%' }}
                      {...getFieldProps('link')}
                      error={Boolean(touched.link && errors.link)}
                      helperText={touched.link && errors.link}
                    />
                  </Grid>

                  {editMode ? (
                    <Grid item xs={12}>
                      <FilePond
                        files={files}
                        onupdatefiles={setFiles}
                        allowMultiple={!truevalue}
                        maxFiles={5}
                        credits={false}
                        allowImagePreview
                        allowImageCrop={truevalue}
                        allowImageTransform
                        // imageCropAspectRatio={'5:3'}
                        name="files"
                        labelIdle="Drag & Drop Images"
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <Box component={'img'} src={ad?.ad?.fileURL} sx={{ width: '100%' }} />
                    </Grid>
                  )}

                  {editMode && (
                    <Grid item xs={12}>
                      {imageError && (
                        <Typography textAlign={'end'} color="error">
                          Please select an Image
                        </Typography>
                      )}
                    </Grid>
                  )}
                </Grid>

                {editMode && (
                  <Grid item xs={12}>
                    <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end" spacing={2}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setEditMode(false);
                          setFormValues(ad);
                        }}
                      >
                        Cancel
                      </Button>
                      <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                        Submit
                      </LoadingButton>
                    </Stack>
                  </Grid>
                )}
              </Form>
            </FormikProvider>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
