import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Button,
  Card,
  Container,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Autocomplete,
  CircularProgress,
  Avatar,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

/// file pond //

import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'; // Crops image
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'; // Changes image to match crop

import Page from '../components/Page';
import Iconify from '../components/Iconify';

registerPlugin(FilePondPluginImagePreview, FilePondPluginImageCrop, FilePondPluginImageTransform);

/// editor //

// Register the plugins

export default function AddFeed() {
  const accountData = JSON.parse(localStorage.getItem('profile'));
  const isSuperAdmin = accountData.accType === 'SUPER_ADMIN';
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [files, setFiles] = useState([]);
  const [country, setCountry] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const truevalue = true;
  const [imageError, setImageError] = useState(false);
  const [authors, setAuthors] = useState([]);
  const [author, setAuthor] = useState({});
  const [open5, setOpen5] = useState(false);
  const [loading5, setLoading5] = useState(true);

  const getCountries = async (options) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/country/admin/all/names`);
      console.log('countries', res);
      setCountries(res.data.data.countries);
    } catch (error) {
      console.log(error);
    }
  };

  const feedSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    // countryId: Yup.array().min(1, 'At least one category is required').required('Category is required'),
    readingTime: Yup.number().min(1).required('Reading Time is required'),
    description: Yup.string().required('Description is required'),
    authorId: Yup.string().required('Author is required'),
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      countryId: [],
      // isPopular: 'false',
      imageDescription: '',
      description: '',
      isBlocked: 'true',
      readingTime: '',
      keywords: '',
      url: '',
      authorId: '',
    },
    validationSchema: feedSchema,
    onSubmit: async () => {
      if (files.length === 0) {
        setImageError(true);
        return;
      }
      const data = new FormData();
      if (values.keywords.length > 0) {
        let keywords = values.keywords.split(',');
        keywords = keywords.map((item) => item.trim());
        keywords = keywords.filter((item) => item.length > 0);
        const uniqueKeywords = [...new Set(keywords)];
        uniqueKeywords.forEach((item) => {
          data.append('keywords', item);
        });
      }
      data.append('title', values.title);
      data.append('countryId', values.countryId);
      data.append('imageDescription', values.imageDescription);
      data.append('description', values.description);
      data.append('authorId', values.authorId);
      // data.append('isPopular', values.isPopular);
      data.append('isBlocked', values.isBlocked);
      data.append('readingTime', values.readingTime);
      data.append('url', values.url);
      files.forEach((item) => {
        data.append('image', item.file);
      });
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/feed/admin`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        toast(res.data.message);
        navigate('/dashboard/feed');
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const superAdminGetAuthors = async () => {
    try {
      setLoading5(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/author/super-admin/active/all`);
      console.log('authors', res);
      setAuthors(res.data.data);
      setLoading5(false);
    } catch (error) {
      console.log(error);
    }
  };

  const adminGetHisAuthor = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/author/admin/active`);
      console.log('author', res);
      setAuthor(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (author._id) {
      formik.setFieldValue('authorId', author._id);
    }
  }, [author]);

  useEffect(() => {
    const image = [];
    files.forEach((item) => {
      image.push(item.file);
    });
    formik.setFieldValue('image', image);
  }, [files]);

  useEffect(() => {
    getCountries();
    if (isSuperAdmin) {
      superAdminGetAuthors();
    } else {
      adminGetHisAuthor();
    }
  }, []);

  useEffect(() => {
    if (files.length > 0) {
      setImageError(false);
    }
  }, [files]);

  return (
    <Page title="Add Feed">
      <Container maxWidth="xl">
        <Card>
          <Stack direction={'row'} justifyContent="space-between" padding={1}>
            <Typography variant="h4"> Add Feed</Typography>
            <Iconify style={{ cursor: 'pointer', fontSize: '20px' }} onClick={() => navigate(-1)} icon="fe:close" />
          </Stack>
          <Box sx={{ paddingX: 1, paddingY: 2 }}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      label="Title"
                      fullWidth
                      sx={{ width: '100%' }}
                      {...getFieldProps('title')}
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                    />
                  </Grid>
                  {isSuperAdmin && (
                    <Grid item xs={6} lg={3} md={4}>
                      <Autocomplete
                        id="asynchronous-demo"
                        fullWidth
                        open={open5}
                        onOpen={() => {
                          setOpen5(true);
                        }}
                        onClose={() => {
                          setOpen5(false);
                        }}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        getOptionLabel={(option) => option.name}
                        options={authors}
                        onChange={(_, value) => {
                          if (value) {
                            formik.setFieldValue('authorId', value._id);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Author"
                            InputProps={{
                              ...params.InputProps,
                            }}
                            error={Boolean(touched.authorId && errors.authorId)}
                            helperText={touched.authorId && errors.authorId}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <Stack direction="row" spacing={2} alignItems="center">
                              <Avatar
                                sx={{ width: 35, height: 35, cursor: 'pointer' }}
                                src={option?.profilePic && option.profilePic.fileUrl}
                              />
                              <Stack direction={'column'}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                  {option.name}
                                </Typography>
                              </Stack>
                            </Stack>
                          </li>
                        )}
                      />
                    </Grid>
                  )}
                  {!isSuperAdmin && (
                    <Grid item xs={6} lg={3} md={4}>
                      <TextField
                        type="text"
                        label="Author"
                        fullWidth
                        sx={{ width: '100%' }}
                        value={author.name}
                        disabled
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={6} lg={3} md={4}>
                    <Autocomplete
                      multiple
                      id="asynchronous-demo"
                      fullWidth
                      open={open}
                      onOpen={() => {
                        setOpen(true);
                      }}
                      onClose={() => {
                        setOpen(false);
                      }}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      getOptionLabel={(option) => option.name}
                      options={countries}
                      loading={loading}
                      onChange={(_, value) =>
                        formik.setFieldValue(
                          'countryId',
                          value.map((v) => v._id)
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(touched.countryId && errors.countryId)}
                          helperText={touched.countryId && errors.countryId}
                          label="Category"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={6} lg={3} md={4} sm={4}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      type="number"
                      label="Reading Time"
                      fullWidth
                      placeholder="Minutes"
                      sx={{ width: '100%' }}
                      {...getFieldProps('readingTime')}
                      error={Boolean(touched.readingTime && errors.readingTime)}
                      helperText={touched.readingTime && errors.readingTime}
                    />
                  </Grid>
                  <Grid item xs={6} lg={3} md={4} sm={4}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      select
                      label="Status"
                      fullWidth
                      sx={{ width: '100%' }}
                      {...getFieldProps('isBlocked')}
                      error={Boolean(touched.isBlocked && errors.isBlocked)}
                      helperText={touched.isBlocked && errors.isBlocked}
                    >
                      <MenuItem value={'false'}>Active</MenuItem>
                      <MenuItem value={'true'}>Blocked</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      label="Description"
                      fullWidth
                      size="small"
                      sx={{ width: '100%' }}
                      multiline
                      rows={4}
                      {...getFieldProps('description')}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      label="URL"
                      fullWidth
                      sx={{ width: '100%' }}
                      {...getFieldProps('url')}
                      error={Boolean(touched.url && errors.url)}
                      helperText={touched.url && errors.url}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      label="Keywords"
                      fullWidth
                      sx={{ width: '100%' }}
                      {...getFieldProps('keywords')}
                      error={Boolean(touched.keywords && errors.keywords)}
                      helperText={touched.keywords && errors.keywords}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FilePond
                      files={files}
                      onupdatefiles={setFiles}
                      allowMultiple
                      maxFiles={5}
                      credits={false}
                      allowImagePreview
                      allowImageCrop={truevalue}
                      allowImageTransform
                      imageCropAspectRatio={'5:3'}
                      name="files"
                      labelIdle="Drag & Drop Images. Keep 5:3 Aspect Ratio"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {imageError && (
                      <Typography textAlign={'end'} color="error">
                        Please select at least one image.
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} mb={2}>
                    <TextField
                      type="text"
                      label="Image Description"
                      fullWidth
                      sx={{ width: '100%' }}
                      {...getFieldProps('imageDescription')}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end">
                    <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                      Submit
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Form>
            </FormikProvider>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
