import { useEffect, useState } from 'react';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import moment from 'moment';
import axios from 'axios';
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const theme = useTheme();

  const [totalFeeds, setTotalFeeds] = useState(0);

  const [todayFeeds, setTodayFeeds] = useState(0);

  const [monthlyFeeds, setMonthlyFeeds] = useState(0);

  const [weeklyFeeds, setWeeklyFeeds] = useState(0);

  const [totalUsers, setTotalUsers] = useState(0);

  const [todayUsers, setTodayUsers] = useState(0);

  const [monthlyUsers, setMonthlyUsers] = useState(0);

  const [weeklyUsers, setWeeklyUsers] = useState(0);

  const [weeklyChartLabels, setWeeklyChartLabels] = useState([]);

  const [weeklyCommentData, setWeeklyCommentData] = useState([]);

  const [weeklyViewData, setWeeklyViewData] = useState([]);

  const [topFiveCountries, setTopFiveCountries] = useState({ chartData: [] });

  const [isLoading, setIsLoading] = useState(true);

  const getTotalFeeds = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/total-feed-count`);
      console.log('total feeds', res.data);
      setTotalFeeds(res.data.data.totalFeedCount);
    } catch (error) {
      console.log(error);
    }
  };

  const getTodayFeeds = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/today-feed-count`);
      console.log('today feeds', res.data);
      setTodayFeeds(res.data.data.totalTodayFeedCount);
    } catch (error) {
      console.log(error);
    }
  };

  const getMonthlyFeeds = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/monthly-feed-count`);
      console.log('monthly feeds', res.data);
      setMonthlyFeeds(res.data.data.totalMonthlyFeedCount);
    } catch (error) {
      console.log(error);
    }
  };

  const getWeeklyFeeds = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/weekly-feed-count`);
      console.log('weekly feeds', res.data);
      setWeeklyFeeds(res.data.data.totalWeeklyFeedCount);
    } catch (error) {
      console.log(error);
    }
  };

  const getTotalUsers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/total-user-count`);
      console.log('total users', res.data);
      setTotalUsers(res.data.data.totalUserCount);
    } catch (error) {
      console.log(error);
    }
  };

  const getTodayUsers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/today-user-count`);
      console.log('today users', res.data);
      setTodayUsers(res.data.data.totalTodayUserCount);
    } catch (error) {
      console.log(error);
    }
  };

  const getMonthlyUsers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/monthly-user-count`);
      console.log('monthly users', res.data);
      setMonthlyUsers(res.data.data.totalMonthlyUserCount);
    } catch (error) {
      console.log(error);
    }
  };

  const getWeeklyUsers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/weekly-user-count`);
      console.log('weekly users', res.data);
      setWeeklyUsers(res.data.data.totalWeeklyUserCount);
    } catch (error) {
      console.log(error);
    }
  };

  const getWeeklyCommentsVisit = async (options) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/weekly-comment-visit-count`, {
        params: options,
      });
      console.log('weekly comments', res.data);
      setWeeklyChartLabels(res.data.data.chartLabels);
      setWeeklyCommentData(res.data.data.chartDataComment);
      setWeeklyViewData(res.data.data.chartDataVisit);
    } catch (error) {
      console.log(error);
    }
  };

  const getTopFiveCountries = async (options) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/top-five-countries`, {
        params: options,
      });
      console.log('top five countries', res.data.data);
      setTopFiveCountries(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  async function fetchData() {
    setIsLoading(true);
    await Promise.all([
      getTotalFeeds(),
      getTodayFeeds(),
      getMonthlyFeeds(),
      getWeeklyFeeds(),
      getTotalUsers(),
      getTodayUsers(),
      getMonthlyUsers(),
      getWeeklyUsers(),
      getWeeklyCommentsVisit({
        startDate: new Date(moment().subtract(8, 'week').startOf('week').format('YYYY-MM-DD')),
        endDate: new Date(moment().endOf('week').format('YYYY-MM-DD')),
      }),
      getTopFiveCountries({
        startDate: new Date(moment().subtract(7, 'days').format('YYYY-MM-DD')),
        endDate: new Date(moment().format('YYYY-MM-DD')),
      }),
    ]);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 2 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Today's Feeds"
              total={todayFeeds}
              color="info"
              icon={'fluent-emoji-high-contrast:newspaper'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Weekly Feeds"
              total={weeklyFeeds}
              color="error"
              icon={'fluent-emoji-high-contrast:newspaper'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Monthly Feeds"
              total={monthlyFeeds}
              color="warning"
              icon={'fluent-emoji-high-contrast:newspaper'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total Feeds" total={totalFeeds} icon={'fluent-emoji-high-contrast:newspaper'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Today's Users" total={todayUsers} color="greenish" icon={'fa:users'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Weekly Users" total={weeklyUsers} color="purplee" icon={'fa:users'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Monthly Users" total={monthlyUsers} color="orangee" icon={'fa:users'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total Users" total={totalUsers} color="balanced" icon={'fa:users'} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            {!isLoading && (
              <AppWebsiteVisits
                title="Visits & Comments"
                getNewData={getWeeklyCommentsVisit}
                chartLabels={weeklyChartLabels}
                chartData={[
                  {
                    name: 'Comments',
                    type: 'area',
                    fill: 'gradient',
                    data: weeklyCommentData,
                    color: '#875C00',
                  },
                  {
                    name: 'Visits',
                    type: 'area',
                    fill: 'gradient',
                    data: weeklyViewData,
                    color: '#4a2486',
                  },
                  // {
                  //   name: 'Team C',
                  //   type: 'line',
                  //   fill: 'solid',
                  //   data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                  // },
                ]}
              />
            )}
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            {!isLoading && (
              <AppCurrentVisits
                getNewData={getTopFiveCountries}
                title="Feeds"
                chartData={topFiveCountries.chartData}
                chartColors={[
                  theme.palette.primary.main,
                  theme.palette.chart.blue[0],
                  theme.palette.chart.violet[0],
                  theme.palette.chart.yellow[0],
                ]}
              />
            )}
          </Grid>
          {/* 
          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/static/mock-images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} height={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} height={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} height={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} height={32} />,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
