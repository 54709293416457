import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box, Stack, Typography, TextField, styled } from '@mui/material';
// components
import moment from 'moment';
import { useState } from 'react';
import { BaseOptionChart } from '../../../components/chart';

// ----------------------------------------------------------------------

AppWebsiteVisits.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const CssTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    fontSize: 13,
  },
  '& .MuiInputLabel-root': {
    fontSize: 13,
  },
  '& .MuiInputLabel-outlined': {
    transform: 'translate(14px, 10px) scale(1)',
  },
  '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
    transform: 'translate(14px, -6px) scale(0.75)',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#78909C',
    },
    '&:hover fieldset': {
      borderColor: '#78909C',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#78909C',
    },
  },
});

export default function AppWebsiteVisits({ title, subheader, chartLabels, chartData, getNewData, ...other }) {
  console.log(chartLabels);
  const chartOptions = merge(BaseOptionChart(), {
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: {
      type: 'datetime',
      axisBorder: {
        show: true,
        color: '#78909C',
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
        color: '#78909C',
      },
    },
    grid: {
      strokeDashArray: 1,
      borderColor: '#90A4AE',
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)}`;
          }
          return y;
        },
      },
    },
  });

  const [startDate, setStartDate] = useState(moment().subtract(8, 'week').startOf('week').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('week').format('YYYY-MM-DD'));
  const [options, setOptions] = useState({ startDate, endDate });

  const handleStartDateChange = (e) => {
    const dateObject = new Date(moment(e.target.value).startOf('day'));
    const temp = { ...options, startDate: dateObject };
    setOptions(temp);
    setStartDate(e.target.value);
    getNewData(temp);
  };
  const handleEndDateChange = (e) => {
    const dateObject = new Date(moment(e.target.value).endOf('day'));
    const temp = { ...options, endDate: dateObject };
    setOptions(temp);
    setEndDate(e.target.value);
    getNewData(temp);
  };
  return (
    <Card {...other} sx={{ height: '100%' }}>
      <Stack direction={'column'} alignItems="center" mt={1.2} padding={1.2} sx={{ width: '100%' }}>
        <Typography marginBottom={1.5} variant="h5">
          {title}
        </Typography>
        <Stack
          direction={'row'}
          paddingX={1}
          spacing={1}
          alignItems="center"
          sx={{ width: '100%' }}
          justifyContent="flex-end"
        >
          <CssTextField
            label="Start Date"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            id="margin-none"
            value={startDate}
            type="date"
            onChange={handleStartDateChange}
          />
          <CssTextField
            label="End Date"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
          />
        </Stack>
      </Stack>

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
