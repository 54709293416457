import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  OutlinedInput,
  InputAdornment,
  Tooltip,
  IconButton,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Filter from './filters/Advertisers';

// components
import Page from './Page';
import Scrollbar from './Scrollbar';
import Iconify from './Iconify';
import Popup from './popups/AdvertisersStatusChange';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function Advertisers() {
  const accountData = JSON.parse(localStorage.getItem('profile'));
  const isSuperAdmin = accountData.accType === 'SUPER_ADMIN';
  const [subscriberData, setAdvertiserData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [subscriberCount, setAdvertiserCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState();
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const advertiserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    contactNumber: Yup.string().required('Contact Number is required'),
    isBlocked: Yup.string().required('Status is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      contactNumber: '',
      isBlocked: true,
    },
    validationSchema: advertiserSchema,
    onSubmit: async () => {
      try {
        if (openEdit) {
          const res = await axios.put(`${process.env.REACT_APP_API_URL}/ad/advertiser`, values);
          if (!res.data.isError) {
            closeEditModal(res.data.message);
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
          getAdvertisers(options);
          resetForm();
        } else {
          const res = await axios.post(`${process.env.REACT_APP_API_URL}/ad/advertiser`, values);
          if (!res.data.isError) {
            closeAddUserModal(res.data.message);
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
          getAdvertisers(options);
          resetForm();
        }
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;
  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.isBlocked}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };

  const closeAddUserModal = () => {
    setOpen(false);
    formik.resetForm();
  };

  const closeEditModal = () => {
    setOpenEdit(false);
    formik.resetForm();
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    if (refresh) {
      getAdvertisers(options);
    }
    if (message) {
      toast(message);
    }
  };
  const openAddUserModal = () => {
    setOpen(true);
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getAdvertisers(temp);
  };

  const getAdvertisers = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/ad/advertisers`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setAdvertiserCount(res.data.data.maxRecords);
          setAdvertiserData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getAdvertisersWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/ad/advertisers`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setAdvertiserCount(res.data.data.maxRecords);
          setAdvertiserData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, name: e.target.value };
    setOptions(temp);
    setPages(0);
    getAdvertisersWithoutLoading(temp);
  };

  const openEditModal = (e, item) => {
    setOpenEdit(true);
    formik.setValues({
      id: item._id,
      name: item.name,
      contactNumber: item.contactNumber,
      isBlocked: item.isBlocked,
    });
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.isBlocked.length) {
      temp.isBlocked = filter.isBlocked.trim();
    }
    if (filter.name) {
      temp.name = filter.name.trim();
    }
    if (filter.contactNumber) {
      temp.contactNumber = filter.contactNumber.trim();
    }
    setOptions(temp);
    setPages(0);
    getAdvertisers(temp);
  };
  useEffect(() => {
    getAdvertisers(options);
  }, []);

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setPages(0);
    setOptions(temp);
    getAdvertisers(temp);
  }, [size]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {/* Add subscriber Dialog */}
          <Dialog open={open} onClose={closeAddUserModal}>
            <DialogTitle>Add Advertiser</DialogTitle>
            <Grid container paddingBottom={'10px'} Width="500px">
              <DialogContent paddingTop="20px">
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Stack direction={'column'} spacing={1} width="500px">
                        <TextField
                          fullWidth
                          label="Name"
                          {...getFieldProps('name')}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                        />
                        <Stack direction={'row'} spacing={1}>
                          <TextField
                            fullWidth
                            label="Contact Number"
                            {...getFieldProps('contactNumber')}
                            error={Boolean(touched.contactNumber && errors.contactNumber)}
                            helperText={touched.contactNumber && errors.contactNumber}
                          />
                          <TextField
                            select
                            fullWidth
                            label="Status"
                            {...getFieldProps('isBlocked')}
                            error={Boolean(touched.isBlocked && errors.isBlocked)}
                            helperText={touched.isBlocked && errors.isBlocked}
                          >
                            <MenuItem value="true">Blocked</MenuItem>
                            <MenuItem value="false">Active</MenuItem>
                          </TextField>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                      <Button onClick={closeAddUserModal}>Cancel</Button>
                      <LoadingButton type="submit" loading={isSubmitting}>
                        Add Advertiser
                      </LoadingButton>
                    </Stack>
                  </Form>
                </FormikProvider>
              </DialogContent>
            </Grid>
          </Dialog>
          {/* Add subscriber dialog end */}
          {/* Edit subscriber Dialog */}
          <Dialog open={openEdit} onClose={closeEditModal}>
            <DialogTitle>Edit Advertiser</DialogTitle>
            <Grid container paddingBottom={'10px'} Width="500px">
              <DialogContent paddingTop="20px">
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Stack direction={'column'} spacing={1} width="500px">
                        <TextField
                          fullWidth
                          label="Name"
                          {...getFieldProps('name')}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                        />
                        <Stack direction={'row'} spacing={1}>
                          <TextField
                            fullWidth
                            label="Contact Number"
                            {...getFieldProps('contactNumber')}
                            error={Boolean(touched.contactNumber && errors.contactNumber)}
                            helperText={touched.contactNumber && errors.contactNumber}
                          />
                          <TextField
                            select
                            fullWidth
                            label="Status"
                            {...getFieldProps('isBlocked')}
                            error={Boolean(touched.isBlocked && errors.isBlocked)}
                            helperText={touched.isBlocked && errors.isBlocked}
                          >
                            <MenuItem value="true">Blocked</MenuItem>
                            <MenuItem value="false">Active</MenuItem>
                          </TextField>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                      <Button onClick={closeEditModal}>Cancel</Button>
                      <LoadingButton type="submit" loading={isSubmitting}>
                        Edit Advertiser
                      </LoadingButton>
                    </Stack>
                  </Form>
                </FormikProvider>
              </DialogContent>
            </Grid>
          </Dialog>
          <Container maxWidth="xl">
            <Stack direction="row" justifyContent="end" alignItems="center">
              {/* <Stack direction="row" spacing={2} padding={2}>
                <SearchStyle
                  value={name}
                  onChange={onSearch}
                  placeholder="Search subscriber..."
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                />
              </Stack> */}
              {isSuperAdmin && (
                <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                  <Button startIcon={<AddIcon />} variant="contained" onClick={openAddUserModal}>
                    Add Advertiser
                  </Button>

                  <Filter applyFilters={applyFilters} />
                </Stack>
              )}
            </Stack>

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl No</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Contact Number</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subscriberData?.map((item, id) => (
                      <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#FFE2E2' } }}>
                        <TableCell>{pages * size + (id + 1)}</TableCell>

                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.contactNumber}</TableCell>
                        <TableCell>
                          <FormControl sx={{ minWidth: 100 }}>
                            <Select
                              size="small"
                              value={item.isBlocked}
                              onChange={(e) => handleStatusChange(e, item, id)}
                            >
                              <MenuItem value="false">Active</MenuItem>
                              <MenuItem value="true">Blocked</MenuItem>
                            </Select>
                            {selectedIndex === id && <Popup item={item} handleClose={handleClose} />}
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={(e) => openEditModal(e, item)} size="small">
                            <Edit />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              onRowsPerPageChange={(e) => {
                setSize(e.target.value);
              }}
              component={'div'}
              count={subscriberCount}
              showFirstButton
              showLastButton
              onPageChange={handlePageChange}
              rowsPerPage={size}
              page={pages}
            />
          </Container>
        </>
      )}
    </>
  );
}
