import PropTypes from 'prop-types';
import { useContext, useMemo, useState } from 'react';
// material
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
//
import palette, {palette1} from './palette';
import typography from './typography';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';
import StateContext from '../Context/Context';

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ThemeProvider({ children }) {
  const { state } = useContext(StateContext);

  const themeOptions = useMemo(
    () => ({
      palette1,
      palette:state?palette1:palette,
      shape: { borderRadius: 8 },
      typography,
      shadows,
      customShadows,
    }),
    [state]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
