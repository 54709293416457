import { Box, Grid, Stack, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import React, { useEffect } from 'react';

export default function EmbedPreview({ item }) {
  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(item.embedCode, {
      ADD_TAGS: ['iframe'],
      ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
    }),
  });

  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(item.embedCode, 'text/html');
    const scripts = doc.getElementsByTagName('script');
    for (let i = 0; i < scripts.length; i += 1) {
      const script = scripts[i];
      const scriptTag = document.createElement('script');
      scriptTag.innerHTML = script.innerHTML;
      scriptTag.src = script.src;
      scriptTag.async = script.async;
      document.head.appendChild(scriptTag);
    }
  }, [item.embedCode]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ maxWidth: '600px', margin: '0 auto' }}
        >
          <Box
            sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            dangerouslySetInnerHTML={sanitizedData()}
          />
          <Typography
            sx={{
              fontFamily: 'Archivo, sans-serif',
              fontSize: '14px',
              textAlign: 'left',
              width: '100%',
              color: 'grey.700',
              marginTop: '5px',
            }}
            variant="body1"
          >
            {item.title}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
}
