// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
    permission: ['SUPER_ADMIN', 'SUB_ADMIN'],
  },
  {
    title: 'categories',
    path: '/dashboard/category',
    icon: getIcon('heroicons:flag-20-solid'),
    permission: ['SUPER_ADMIN', 'SUB_ADMIN'],
  },
  {
    title: 'feeds',
    path: '/dashboard/feed',
    icon: getIcon('fluent-emoji-high-contrast:newspaper'),
    permission: ['SUPER_ADMIN', 'SUB_ADMIN'],
  },
  {
    title: 'users',
    path: '/dashboard/user',
    icon: getIcon('eva:people-fill'),
    permission: ['SUPER_ADMIN', 'SUB_ADMIN'],
  },
  {
    title: 'comments',
    path: '/dashboard/comment',
    icon: getIcon('material-symbols:comment-rounded'),
    permission: ['SUPER_ADMIN', 'SUB_ADMIN'],
  },
  {
    title: 'subscriptions',
    path: '/dashboard/subscription',
    icon: getIcon('eos-icons:product-subscriptions'),
    permission: ['SUPER_ADMIN'],
  },
  {
    title: 'admins',
    path: '/dashboard/account',
    icon: getIcon('eos-icons:admin'),
    permission: ['SUPER_ADMIN'],
  },
  {
    title: 'Advertisements',
    path: '/dashboard/ads',
    icon: getIcon('mdi:ads'),
    permission: ['SUPER_ADMIN'],
  },
  {
    title: 'authors',
    path: '/dashboard/author',
    icon: getIcon('fluent-emoji-high-contrast:writing-hand'),
    permission: ['SUPER_ADMIN'],
  },
  {
    title: 'promotions',
    path: '/dashboard/promotion',
    icon: getIcon('mdi:link-box-variant'),
    permission: ['SUPER_ADMIN', 'SUB_ADMIN'],
  },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: getIcon('eva:shopping-bag-fill'),
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon('eva:file-text-fill'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon('eva:lock-fill'),
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon('eva:person-add-fill'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill'),
  // },
];

export default navConfig;
