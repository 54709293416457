import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Button, CircularProgress, Container, IconButton, Stack, Typography } from '@mui/material';
import { Close, Comment, ViewAgenda, RemoveRedEye, ThumbDownAlt, ThumbUpAlt } from '@mui/icons-material';

import Page from '../components/Page';
import MainSection from '../components/viewFeed/MainSection';
import AddNewSection from '../components/viewFeed/AddNewSection';
import PieChartSection from '../components/viewFeed/PieChartSection';
import BarGraphSection from '../components/viewFeed/BarGraphSection';
import ImageSection from '../components/viewFeed/ImageSection';
import TextSection from '../components/viewFeed/TextSection';
import TextSectionView from '../components/viewFeed/TextSectionView';
import ImageViewSection from '../components/viewFeed/ImageViewSection';
import PieChartViewSection from '../components/viewFeed/PieChartViewSection';
import BarGraphViewSection from '../components/viewFeed/BarGraphViewSection';
import PreviewPage from './PreviewPage';
import FeedComments from '../components/FeedComments';
import VideoLinkSection from '../components/viewFeed/VideoLinkSection';
import VideoSectionView from '../components/viewFeed/VideoSectionView';
import VideoSection from '../components/viewFeed/VideoSection';
import VideoFileSectionView from '../components/viewFeed/VideoFileSectioView';
import EmbedSection from '../components/viewFeed/EmbedSection';
import EmbedSectionView from '../components/viewFeed/EmbedSectionView';

export default function ViewFeed() {
  const accountData = JSON.parse(localStorage.getItem('profile'));
  const isSuperAdmin = accountData.accType === 'SUPER_ADMIN';
  const adminId = accountData._id;
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [feed, setFeed] = useState({});
  const [section, setSection] = useState();
  const [contentsArray, setContentsArray] = useState([]);
  const [preview, setPreview] = useState(false);
  const [commentsView, setCommentsView] = useState(false);
  const [feedOwner, setFeedOwner] = useState(false);

  const getFeed = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/feed/admin`, {
        params: { id },
      });
      console.log('feed', res);
      setFeed(res.data.data.feed);
      setContentsArray(res.data.data.feed.content);
      if (res.data.data.feed.content) {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFeed();
  }, []);

  useEffect(() => {
    console.log('sections', section);
  }, [section]);

  useEffect(() => {
    if (feed._id) {
      if ((feed?.adminId && feed?.adminId === adminId) || isSuperAdmin) {
        setFeedOwner(true);
      } else {
        setFeedOwner(false);
      }
    }
  }, [feed]);

  return (
    <Page title="Feed">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container maxWidth="xl">
          <Container maxWidth={preview ? 'lg' : 'xl'}>
            <Stack justifyContent={'flex-end'} direction={{ xs: 'column', sm: 'row' }} spacing={2} mb={1}>
              <Stack direction={'row'} spacing={2}>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={0.3}>
                  <RemoveRedEye
                    sx={{
                      color: 'primary.main',
                    }}
                  />
                  <Typography>{feed.viewCount}</Typography>
                </Stack>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={0.3}>
                  <ThumbUpAlt
                    sx={{
                      color: 'balanced.dark',
                    }}
                  />
                  <Typography> {feed.likeCount}</Typography>
                </Stack>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={0.3}>
                  <ThumbDownAlt
                    sx={{
                      color: 'balanced.dark',
                    }}
                  />
                  <Typography> {feed.dislikeCount}</Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => setCommentsView(!commentsView)}
                  spacing={0.3}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  <Comment
                    sx={{
                      color: 'purplee.dark',
                    }}
                  />
                  <Typography> {feed.comments}</Typography>
                </Stack>
              </Stack>
              {/* {!commentsView && (
                <Button startIcon={<Comment />} variant="contained" size="small" color="orangee">
                  {'Comments'}
                </Button>
              )} */}
              {!commentsView && (
                <Button
                  variant="contained"
                  startIcon={preview ? <Close /> : <RemoveRedEye />}
                  onClick={() => setPreview(!preview)}
                >
                  {preview ? 'Close Preview' : 'Preview'}
                </Button>
              )}
            </Stack>
          </Container>
          {!preview && !commentsView && (
            <Stack spacing={1} sx={{ width: '100%' }}>
              <MainSection feed={feed} getFeed={getFeed} />
              {contentsArray.map((content, key) => (
                <Stack key={key} spacing={1}>
                  {content.contentType === 'TEXT' && (
                    <TextSectionView
                      index={key}
                      content={content}
                      getFeed={getFeed}
                      feed={feed}
                      feedOwner={feedOwner}
                    />
                  )}
                  {content.contentType === 'IMAGE' && (
                    <ImageViewSection
                      index={key}
                      content={content}
                      getFeed={getFeed}
                      feed={feed}
                      feedOwner={feedOwner}
                    />
                  )}
                  {content.contentType === 'PIE_CHART' && (
                    <PieChartViewSection
                      index={key}
                      content={content}
                      getFeed={getFeed}
                      feed={feed}
                      feedOwner={feedOwner}
                    />
                  )}
                  {content.contentType === 'BAR_GRAPH' && (
                    <BarGraphViewSection
                      index={key}
                      content={content}
                      getFeed={getFeed}
                      feed={feed}
                      feedOwner={feedOwner}
                    />
                  )}
                  {content.contentType === 'VIDEO_LINK' && (
                    <VideoSectionView
                      index={key}
                      content={content}
                      getFeed={getFeed}
                      feed={feed}
                      feedOwner={feedOwner}
                    />
                  )}
                  {content.contentType === 'EMBED_CODE' && (
                    <EmbedSectionView
                      index={key}
                      content={content}
                      getFeed={getFeed}
                      feed={feed}
                      feedOwner={feedOwner}
                    />
                  )}
                  {content.contentType === 'VIDEO' && (
                    <VideoFileSectionView
                      index={key}
                      content={content}
                      getFeed={getFeed}
                      feed={feed}
                      feedOwner={feedOwner}
                    />
                  )}
                </Stack>
              ))}
              {!section && feedOwner && <AddNewSection setSection={setSection} feed={feed} />}
              {section === 'PIE_CHART' && <PieChartSection setSection={setSection} feed={feed} getFeed={getFeed} />}
              {section === 'BAR_GRAPH' && <BarGraphSection setSection={setSection} feed={feed} getFeed={getFeed} />}
              {section === 'IMAGE' && <ImageSection setSection={setSection} feed={feed} getFeed={getFeed} />}
              {section === 'VIDEO' && <VideoSection setSection={setSection} feed={feed} getFeed={getFeed} />}
              {section === 'TEXT' && <TextSection setSection={setSection} feed={feed} getFeed={getFeed} />}
              {section === 'VIDEO_LINK' && <VideoLinkSection setSection={setSection} feed={feed} getFeed={getFeed} />}
              {section === 'EMBED_CODE' && <EmbedSection setSection={setSection} feed={feed} getFeed={getFeed} />}
            </Stack>
          )}
          {preview && !commentsView && <PreviewPage contents={contentsArray} feed={feed} />}
          {commentsView && <FeedComments feed={feed} setCommentsView={setCommentsView} />}
        </Container>
      )}
    </Page>
  );
}
