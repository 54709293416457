import {
  Button,
  Container,
  Card,
  Grid,
  Box,
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  MenuItem,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import '../../style.css';

/// editor //
import { Editor } from 'react-draft-wysiwyg';
import {
  CharacterMetadata,
  ContentState,
  convertToRaw,
  EditorState,
  getDefaultKeyBinding,
  KeyBindingUtil,
  RichUtils,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import UpdateOeder from '../UpdateOeder';

export default function TextSectionView({ setSection, feed, content, getFeed, index, feedOwner }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [error, setError] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [htmlString, setHtmlString] = useState(content.text);
  const [contentText, setContentText] = useState(content.text);
  const [deletePopup, setDeletePopup] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const containerRef = useRef(null);

  const removeInlineStyles = (editorState, retainInlineStyles = []) => {
    const blocks = editorState
      .getCurrentContent()
      .getBlockMap()
      .map((singleBlock) =>
        singleBlock.set(
          'characterList',
          singleBlock.getCharacterList().map((charMetaData) => {
            if (!charMetaData) {
              return charMetaData;
            }
            const entity = charMetaData.getEntity();
            const style = charMetaData.getStyle();
            return CharacterMetadata.create({
              entity,
              style: style.intersect(retainInlineStyles),
            });
          })
        )
      )
      .toArray();

    return EditorState.createWithContent(ContentState.createFromBlockArray(blocks));
  };

  const removeFormatting = () => {
    const newState = removeInlineStyles(editorState);
    setEditorState(newState);
  };

  function myKeyBindingFn(e) {
    if (e.keyCode === 13 /* 'Enter' key */ && !KeyBindingUtil.hasCommandModifier(e)) {
      return 'soft-newline';
    }
    return getDefaultKeyBinding(e);
  }

  const handleKeyCommand = (command, editorState) => {
    if (command === 'soft-newline') {
      const newState = RichUtils.insertSoftNewline(editorState);
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const blockStyleFn = (contentBlock) => {
    const type = contentBlock.getType();
    if (type === 'unstyled') {
      return 'indent';
    }
  };

  const deleteSection = async () => {
    try {
      setButtonLoading(true);
      const res = await axios.delete(`${process.env.REACT_APP_API_URL}/feed/admin/content`, {
        data: {
          id: feed._id,
          contentId: content._id,
        },
      });
      console.log('delete text section', res);
      getFeed();
      toast(res.data.message);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setButtonLoading(false);
    }
  };

  const updateText = async () => {
    try {
      if (error) {
        return;
      }
      setButtonLoading(true);
      const editorData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      if (editorData.length > 8) {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/feed/admin/content/text`, {
          id: feed._id,
          content: editorData,
          contentId: content._id,
        });
        console.log('add text section', res);
        getFeed();
        setEditMode(false);
        toast(res.data.message);
        setButtonLoading(false);
      } else {
        setButtonLoading(false);
      }
    } catch (error) {
      console.log(error);
      setButtonLoading(false);
    }
  };

  const updateOrder = async (e) => {
    try {
      setButtonLoading(true);
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/feed/admin/content-order`, {
        id: feed._id,
        currentIndex: index,
        newIndex: e.target.value,
      });
      console.log('update order', res);
      getFeed();
      toast(res.data.message);
      setButtonLoading(false);
    } catch (error) {
      console.log(error);
      setButtonLoading(false);
    }
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const handleDelete = () => {
    setDeletePopup(true);
  };

  useEffect(() => {
    const editorData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    if (editorData.length > 8) {
      setError(false);
    } else {
      setError(true);
    }
  }, [editorState]);

  useEffect(() => {
    setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(`${contentText}`))));
  }, [contentText]);

  return (
    <>
      {/* delete popup  */}
      <Dialog
        open={deletePopup}
        onClose={() => setDeletePopup(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Delete Section'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this section?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeletePopup(false)}>Cancel</Button>
          <Button onClick={deleteSection} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* delete popup  */}
      <Card sx={{ padding: 1 }}>
        {editMode && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  minHeight: '250px',
                  border: 'solid 1px #e6e6e6',
                  borderRadius: '10px',
                  justifyContent: 'unset',
                  alignItems: 'unset',
                  lineHeight: 'unset',
                  textAlign: 'unset',
                  fontSize: 'unset',
                  fontWeight: 'unset',
                  fontFamily: 'unset',
                  color: 'unset',
                }}
                padding={1}
              >
                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                  <Button variant="outlined" size="small" onClick={removeFormatting}>
                    Remove Formatting
                  </Button>
                </Stack>
                <Editor
                  // eslint-disable-next-line react/jsx-no-bind
                  // keyBindingFn={myKeyBindingFn}
                  // handleKeyCommand={handleKeyCommand}
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName editor"
                  blockStyleFn={blockStyleFn}
                  onEditorStateChange={onEditorStateChange}
                  onBlur={() => setError(true)}
                  toolbar={{
                    options: ['inline', 'blockType', 'list', 'link', 'colorPicker', 'history'],
                    inline: {
                      inDropdown: false,
                      options: ['bold', 'italic', 'underline', 'strikethrough'],
                    },
                    blockType: {
                      inDropdown: true,
                      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
                    },
                    list: {
                      inDropdown: false,
                      options: ['unordered', 'ordered'],
                    },
                    textAlign: {
                      inDropdown: undefined,
                      options: [],
                    },
                    link: {
                      inDropdown: false,
                      defaultTargetOption: '_blank',
                    },
                    colorPicker: {
                      inDropdown: false,
                    },
                    remove: { icon: undefined, className: 'toolbar-remove', component: undefined },
                    history: {
                      inDropdown: false,
                      options: ['undo', 'redo'],
                    },
                  }}
                />
              </Box>
              {error && (
                <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end">
                  <Typography variant="caption" color="error">
                    Description is required
                  </Typography>
                </Stack>
              )}
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end" spacing={2}>
                <Button variant="outlined" onClick={() => setEditMode(false)}>
                  Cancel
                </Button>
                <LoadingButton loading={buttonLoading} variant="contained" onClick={updateText}>
                  Submit
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        )}
        {!editMode && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {feedOwner && (
                <Stack direction="row" sx={{ width: '100%' }} justifyContent="space-between" spacing={1}>
                  <UpdateOeder index={index} getFeed={getFeed} feed={feed} />

                  <Stack spacing={1} direction={'row'}>
                    <LoadingButton
                      loading={buttonLoading}
                      size="small"
                      variant="outlined"
                      color="error"
                      onClick={handleDelete}
                    >
                      Delete
                    </LoadingButton>
                    <Button size="small" variant="outlined" onClick={() => setEditMode(true)}>
                      Edit
                    </Button>
                  </Stack>
                </Stack>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ padding: '50px' }} className="textContent markdown">
                <Box ref={containerRef} padding={0} dangerouslySetInnerHTML={{ __html: htmlString }} />
              </Box>
            </Grid>
          </Grid>
        )}
      </Card>
    </>
  );
}
