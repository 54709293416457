import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
// material
import { styled } from '@mui/material/styles';
import {
  Table,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Container,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  OutlinedInput,
  InputAdornment,
  Icon,
  IconButton,
} from '@mui/material';
import moment from 'moment';
import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { Delete, Visibility } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Filter from './filters/Advertisements';

// components
import Page from './Page';
import Scrollbar from './Scrollbar';
import Iconify from './Iconify';
import Popup from './popups/AdvertisementStatusChange';
import Popup1 from './popups/AdvertisementDelete';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function Advertisementss() {
  const accountData = JSON.parse(localStorage.getItem('profile'));
  const isSuperAdmin = accountData.accType === 'SUPER_ADMIN';
  const navigate = useNavigate();
  const [mailData, setAdvertisementData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [mailCount, setAdvertisementCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedIndex1, setSelectedIndex1] = useState();
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [open, setOpen] = useState(false);

  const mailSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: mailSchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/subscription/admin/mail`, values);
        if (!res.data.isError) {
          closeAddUserModal(res.data.message);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        getAdvertisements(options);
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;
  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.isBlocked}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };

  const handleDelete = (index) => {
    setSelectedIndex1(index);
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    if (refresh) {
      getAdvertisements(options);
    }
    if (message) {
      toast(message);
    }
  };

  const handleClose1 = (refresh = false, message = '') => {
    setSelectedIndex1();
    if (refresh) {
      getAdvertisements(options);
    }
    if (message) {
      toast(message);
    }
  };

  const closeAddUserModal = () => {
    setOpen(false);
    formik.resetForm();
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getAdvertisements(temp);
  };

  const getAdvertisements = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/ad/advertisements`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setAdvertisementCount(res.data.data.maxRecords);
          setAdvertisementData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getAdvertisementsWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/ad/advertisements`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setAdvertisementCount(res.data.data.maxRecords);
          setAdvertisementData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, name: e.target.value };
    setOptions(temp);
    setPages(0);
    getAdvertisementsWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.isBlocked.length) {
      temp.isBlocked = filter.isBlocked.trim();
    }
    if (filter.device) {
      temp.device = filter.device.trim(); 
    }
    setOptions(temp);
    setPages(0);
    getAdvertisements(temp);
  };
  useEffect(() => {
    getAdvertisements(options);
  }, []);

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setPages(0);
    setOptions(temp);
    getAdvertisements(temp);
  }, [size]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Container maxWidth="xl">
            <Stack direction="row" justifyContent="end" alignItems="center">
              {/* <Stack direction="row" spacing={2} padding={2}>
                <SearchStyle
                  value={name}
                  onChange={onSearch}
                  placeholder="Search mail..."
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                />
              </Stack> */}
              {isSuperAdmin && (
                <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                  <Button
                    startIcon={<AddIcon />}
                    variant="contained"
                    onClick={() => navigate('/dashboard/ads/advertisement/add')}
                  >
                    Add Advertisement
                  </Button>

                  <Filter applyFilters={applyFilters} />
                </Stack>
              )}
            </Stack>

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl No</TableCell>
                      <TableCell>Advertiser</TableCell>
                      <TableCell>Ad</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Device</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mailData?.map((item, id) => (
                      <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#FFE2E2' } }}>
                        <TableCell sx={{ width: '80px' }}>{pages * size + (id + 1)}</TableCell>
                        <TableCell>{item?.advertiser?.name}</TableCell>
                        <TableCell sx={{ width: '220px' }}>
                          <Box component={'img'} src={item?.ad?.fileURL} sx={{ width: '200px', maxHeight: '150px' }} />
                        </TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.device}</TableCell> 
                        <TableCell sx={{ width: '130px' }}> 
                          <FormControl sx={{ minWidth: 100 }}>
                            <Select
                              size="small"
                              value={item.isBlocked}
                              onChange={(e) => handleStatusChange(e, item, id)}
                            >
                              <MenuItem value="false">Active</MenuItem>
                              <MenuItem value="true">Blocked</MenuItem>
                            </Select>
                            {selectedIndex === id && <Popup item={item} handleClose={handleClose} />}
                          </FormControl>
                        </TableCell>
                        <TableCell sx={{ width: '130px' }}>
                          <Stack direction="row" spacing={2}>
                            <IconButton
                              color="primary"
                              onClick={() => navigate(`/dashboard/ads/advertisement/view/${item._id}`)}
                            >
                              <Visibility />
                            </IconButton>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              onRowsPerPageChange={(e) => {
                setSize(e.target.value);
              }}
              component={'div'}
              count={mailCount}
              showFirstButton
              showLastButton
              onPageChange={handlePageChange}
              rowsPerPage={size}
              page={pages}
            />
          </Container>
        </>
      )}
    </>
  );
}
