import { Stack, Typography } from '@mui/material';
import React from 'react';
import Banner from './banner/Banner';

export default function ImagesPreview({ item }) {
  const images = item.image;
  return (
    <>
      <Banner image={images} />
      <Stack direction="column" justifyContent="center" alignItems="center" sx={{ maxWidth: '600px', margin: '0 auto' }}>
      <Typography variant="body1" sx={{  fontFamily: 'Archivo, sans-serif', fontSize: '14px', textAlign: 'left', width: '100%', color: 'grey.700', marginTop:"5px" }}>
        <blockquote>
          {item.description && item.description?.charAt(0).toUpperCase() + item.description?.slice(1)}
        </blockquote>
      </Typography>
      </Stack>
    </>
  );
}
